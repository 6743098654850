@import "src/app/app.component.scss";
@import "src/app/components/header/header.component.scss";
@import "src/app/components/home/home.component.scss";
@import "src/app/components/login/login.component.scss";
@import "src/app/components/search-form/search-form.component.scss";
@import "src/app/components/profile/profile.component.scss";
@import "src/app/components/ad-detail/ad-detail.component.scss";
@import "src/app/components/file-upload/file-upload.component.scss";
@import "src/app/components/create-section/create-section.component.scss";
@import "src/app/components/location-links/location-links.component.scss";
@import "src/app/components/image-slider/image-slider.component.scss";
@import "src/app/components/profile-favorites/profile-favorites.component.scss";
@import "src/app/components/profile-view-history/profile-view-history.component.scss";
@import "src/app/components/profile-messages/profile-messages.component.scss";
@import "src/app/components/profile-comments/profile-comments.component.scss";
@import "src/app/components/comments/comments.component.scss";
@import "src/app/components/register/register.component.scss";
@import "src/app/components/detail-page/detail-page.component.scss";
@import "src/app/components/photos/photos.component.scss";
@import "src/app/components/ad-form/ad-form.component.scss";

@import "src/app/components/modals/preview-modal/preview-modal.component.scss";
@import "src/app/components/modals/photo-preview/photo-preview-modal.component.scss";

@import "src/app/components/popovers/sort-ads/sort-ads-popover.component.scss";

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: "Open Sans", Roboto, sans-serif; // Muli
  --ion-font-family: "Open Sans", Roboto, sans-serif; // Muli
}

a {
  color: var(--ion-link-color);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.content-body {
  min-height: calc(100vh - 306px);
}

.button-small {
  --padding-start: 1em;
  --padding-end: 1em;
}

input,
optgroup,
textarea,
select,
ion-datetime-button::part(native) {
  color: var(--ion-text-color);
  background: var(--ion-input-background);
}

ion-item-divider {
  color: var(--ion-text-color);
}

ion-radio.md.in-item {
  margin-inline-end: 24px;
}

ion-item ion-thumbnail,
ion-item ion-avatar {
  margin-right: 16px;
}

.left-menu ion-icon[slot=start] {
  margin-inline-end: 16px;
}

.preview-modal::part(content) {
  --width: 100%;
  --height: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.preview-post-modal::part(content) {
  --width: 100%;
  --height: 100%;
  max-width: 800px;
  margin: 16px auto;
}

.photo-preview-modal::part(content) {
  --width: 100%;
  --height: 100%;
}

.login-modal::part(content) {
  box-shadow: 0 1px 4px var(--ion-box-shadow-color);
}

.my-div-icon {
  padding: 0.2em !important;
  width: 60px !important;
  height: auto !important;
  background-color: var(--ion-color-primary-tint);
  color: #f9f9f9;
  border-radius: 3px;
  text-align: center;
  box-shadow: 0 0 3px #777;
  border: 1px solid #bbb;
  // margin-left: -35px !important;
  // margin-top: -26px !important;
  font-weight: bold;
  
  &.orange,
  &:hover {
    background-color: #f37700;
    
    .bottom-arrow {
      background-color: #f37700;
    }
  }
  
  &.price-per-m {
    width: 80px !important;
  }
  
  .bottom-arrow {
    width: 8px;
    height: 8px;
    transform: rotate(45deg) !important;
    position: absolute !important;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    background-color: var(--ion-color-primary-tint);
    box-shadow: 1px 1px 1px #777;
    // box-shadow: 1px 1px 4px #666 !important;
    
    &:hover {
      background-color: #f37700;
    }
  }
}

.cc-revoke {

  &.cc-bottom {
    transform: translateY(2em);
    opacity: 0.6;
  }

  &.cc-right {
    right: 4px !important;
  }
}
