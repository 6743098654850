.comments {
  padding: 0 8px;
  
  ion-item,
  ion-list {
    --background: transparent;
    background: transparent;
  }
  
  @media only screen and (min-width: 575px) {
    padding: 0 16px;
  }
  
  ion-avatar {
    --border-radius: 50%;
    overflow: hidden;
  }
  
  .thumbs-button {
    ion-icon {
      font-size: 18px;
    }
  }
  
  .new-comment-item {
    --padding-start: 0;
    
    ion-avatar {
      margin: 16px 16px 8px 0;
      margin-bottom: auto;
    }
    
    .new-comment-input {
      width: 100%;
      border: none;
      margin-bottom: 8px;
      padding: 8px;
      font-size: 14px;
      border-radius: 4px;
    }
    
    .message-counter {
      float: right;
      font-size: 80%;
      margin-right: 8px;
      
      &.length-warning {
        color: red;
      }
    }
  }
  
  span.likes-num {
    margin-right: 8px;
    margin-left: 6px;
    font-size: 14px;
    color: var(--ion-text-color-secondary);
  }
  
  .comment-item {
    --padding-start: 0;
    --inner-padding-end: 0;
    
    ion-avatar {
      margin: 16px 16px 8px 0;
      margin-bottom: auto;
    }
    
    .comment-date {
      font-size: 90%;
    }

    .comment-message {
      color: var(--ion-text-color);
      font-size: 15px;
      white-space: pre-line;
    }
    
    .more-button {
      margin-top: 16px;
      min-width: 24px;
      margin-bottom: auto;
      
      ion-icon {
        font-size: 18px;
      }
    }
    
    .edit-comment-input {
      width: 100%;
      border: none;
      margin-bottom: 8px;
      padding: 10px;
    }
    
    .message-counter {
      float: right;
      font-size: 80%;
      margin-right: 8px;
      
      &.length-warning {
        color: red;
      }
    }
    
    .ad-owner {
      background-color: #888;
      color: white;
      border-radius: 50px;
      padding: 0 6px;
    }
    
    .show-hide-replies {
      margin-top: 4px;
      cursor: pointer;
    }
  }
  
  .reply-comment-item {
    --background: transparent;
    padding-left: 40px;
    --inner-padding-end: 0;
    color: var(--ion-text-color-secondary);
    
    &.reply-form {
      padding-left: 0px !important;
    }
    
    ion-avatar {
      margin: 12px 12px 8px 0;
      margin-bottom: auto;
      width: 26px;
      height: 26px;
      
      img {
        width: 26px;
        height: 26px;
      }
    }
    
    @media only screen and (min-width: 575px) {
      padding-left: 50px;
      
      ion-avatar {
        margin: 16px 16px 8px 0;
        min-width: 30px;
        min-height: 30px;
        
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
    
    .reply-date {
      font-size: 90%;
    }
    
    .more-button {
      margin-top: 16px;
      min-width: 24px;
      margin-bottom: auto;
      
      ion-icon {
        font-size: 18px;
      }
    }
    
    .reply-comment-input {
      width: 100%;
      border: none;
      margin-bottom: 8px;
      padding: 8px;
      font-size: 14px;
      border-radius: 4px;
    }
    
    .message-counter {
      float: right;
      font-size: 80%;
      margin-right: 8px;
      
      &.length-warning {
        color: red;
      }
    }
  }

  h1 {
    color: #f33510;
    border-bottom: 1px solid #f33510;
    padding-bottom: 8px;
    margin-top: 32px;
    margin-bottom: 24px;
    font-size: 18px;

    @media only screen and (min-width: 575px) {
      font-size: 24px;
    }
  }
}
