ion-header.detail-page-header {
  .action-button {
    margin-right: -4px;
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  
    @media only screen and (min-width: 576px) {
      margin-right: 0px;
    }

    .button-native {
      padding: 0 4px;
      padding-inline-start: 4px !important;
      padding-inline-end: 4px !important;
  
      @media only screen and (min-width: 576px) {
        padding: 0 10px;
      }
    }
  }

  .icon {
    &.close-icon {
      font-size: 22px;
    }
  }

  .saved {
    color: #f33510;
  }
  
  .approve-button {
    --background: #7cf7ac;
  }
}

// ion-content {
.detail-page-content {
  .inactive-message {
    padding-top: 100px;
    text-align: center;
  }
}
