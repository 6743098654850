.location-links {

  p {
    color: #949494;
    width: 100%;
    // padding: 40px 80px 0 0;

    &.location-link {
      max-width: 360px;

      a {
        text-decoration: none;
        font-size: 15px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
