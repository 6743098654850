ion-toolbar.main-header {
  &.bottom-shadow {
    box-shadow: 0 1px 4px var(--ion-box-shadow-color);
    z-index: 99;
  }
  
  .page-logo {
    height: 36px;
    margin-top: 4px;
    cursor: pointer;
    
    @media only screen and (min-width: 576px) {
      margin-left: 8px;
    }
  }

  .user-avatar {
    width: 32px;
    height: 32px;

    img {
      border-radius: 50%;
    }
  }
  
  .toolbar-background {
    background-color: #009aff;
    // background-image: linear-gradient(#009aff, #1565C0); // 1. #2b86e0
  }
  
  ion-icon.icon.icon-md.icon-md-white.ion-md-close {
    font-size: 1.4em;
  }
  
  .button-back {
    float: left;
    box-shadow: none;
    background-color: #18af90; // #007ee5;
    color: white;
    
    &:hover {
      background-color: #5cc7b1;
      background-color: rgba(255, 255, 255, 0.25);
    }
  }
  
  .button-menu-desktop {
    float: left;
    box-shadow: none;
    background-color: #18af90; // #007ee5;
    color: white;
    
    &:hover {
      background-color: #5cc7b1;
      background-color: rgba(255, 255, 255, 0.25);
    }
    
    &:hover:not(.disable-hover) {
      background-color: #5cc7b1;
      background-color: rgba(255, 255, 255, 0.25);
    }
  }
  
  .bar-button-default {
    padding: 0 5px;
    
    &:hover {
      color: #ffffff;
      background-color: #5cc7b1;
      background-color: rgba(255, 255, 255, 0.25);
    }
    
    &:hover:not(.disable-hover) {
      color: #ffffff;
      background-color: #5cc7b1;
      background-color: rgba(255, 255, 255, 0.25);
    }
  }
  
  .search-bar {
    // margin: 0 auto;
    width: 80%;
    max-width: 400px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    
    .searchbar-input {
      padding: 4px 55px;
    }
    
    .searchbar-search-icon {
      top: 10px;
    }
  }
  
  ion-title.title.title-md {
    line-height: 45px;
    float: left;
  }
}

ion-toolbar.toolbar-subheader {
  padding: 0 8px;
  min-height: 36px;

  @media screen and (min-width: 576px) {
    padding: 0 20px;
    min-height: 46px;
  }


  .search-bar-agency {
    // margin: 0 auto;
    width: 80%;
    max-width: 400px;
    // left: 0;
    // right: 0;
    line-height: 36px;
    background-color: white;
    max-height: 38px;
    padding: 0 12px;
    border-radius: 4px;
    --padding-start: 12px;
    min-height: 42px;
    float: left;
  }

  .buttons-last-slot {
    float: right;
  }
  
  .result-count {
    font-size: 15px;
  }
  
  .toolbar-background {
    border-color: #b2b2b2;
    background: #fafafa;
    box-shadow: inset 1px 4px 9px -6px;
  }
  
  .toolbar-button {
    color: var(--ion-button-color-toolbar);
    text-transform: none;
    transition: all 0.18s;
    --border-width: 1px;
    
    .capitalized {
      text-transform: capitalize;
    }

    .filter-num {
      margin-left: 8px;
      font-weight: bold;
    }
    
    ion-icon {
      margin: 0 4px;
      font-size: 18px;
      
      &.swap-icon {
        transform: rotate(90deg);
      }
    }
    
    &.save-search {
      --background: #5cc7b1;
      --color: #f9f9f9;
      border-radius: 4px;

      span,
      ion-icon {
        color: #f9f9f9;
      }
      
      &:hover {
        --background: #66dac2;
        
        span,
        .icon {
          color: #fff;
        }
      }
    }
    
    .arrow-forward {
      &.up {
        transform: rotate(-90deg);
      }
      
      &.down {
        transform: rotate(90deg);
      }
    }
  }
}

.popover-page {
  button.item.item-block.item-md {
    background-color: transparent;
    color: #666;
  }
}
