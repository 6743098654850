ion-header.login-header {
  ion-toolbar {
    padding: 0 4px 0 16px;

    .page-logo {
      height: 36px;
      margin-top: 4px;
      cursor: pointer;
    }
  }
}

app-register {
  .register-page {
    app-login {
      max-width: 400px;
    }
  }
}
