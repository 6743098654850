.profile-favorites-component {
  --background: transparent;
  background-color: var(--ion-page-background);

  ion-grid {
    max-width: 1000px;

    ion-item {
      &.inactive-ad {
        --background: var(--ion-toolbar-background);
        opacity: 0.7;
      }
    }
  }
}
