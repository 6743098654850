.set-sort-popover {

  .set-sort-button {

    .arrow-forward {
      margin-right: 12px;

      &.up {
        transform: rotate(-90deg);
      }

      &.down {
        transform: rotate(90deg);
      }
    }
  }
}