.profile-component {
  --background: transparent;
  background-color: var(--ion-page-background);

  .user-not-found {
    padding: 100px;
    text-align: center;
  }

  ion-grid {
    max-width: 1000px;

    &.page-content {
      padding-bottom: 100px;

      .no-items-message {
        font-size: 16px;
        text-align: center;
        padding: 30px 0;
        width: 100%;
      }
    }

    ion-item {
      &.inactive-ad {
        --background: var(--ion-toolbar-background);
      }
    }

    ion-input,
    ion-textarea {
      --background: var(--ion-input-background);
      border-radius: 4px;
      --padding-start: 8px;
      line-height: 36px;
    }

    ion-input {
      min-height: 42px;
    }

    ion-avatar.user-avatar {
      --border-radius: 50%;
      overflow: hidden;
      width: 80px;
      height: 80px;

      img {
        width: 80px;
        height: 80px;
        cursor: pointer;
      }
    }

    app-file-upload {
      position: absolute;
      top: 42px;
      left: 10px;
    }

    ion-label.profile-label {
      flex: 1;
      max-width: none;
    }

    .contact-area,
    .info-area {
      margin-bottom: 24px;

      .bio {
        white-space: pre-line;
      }
    }

    .overview-info {
      margin: 16px 0;

      .info-icon {
        margin-right: 8px;
      }
    }
  }
}
