.create-text {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .create-ad-button {
    --background: #01df7b;
    color: white;
    --box-shadow: 0 3px 7px 0 rgba(0, 201, 110, 0.12);
    --border-color: none;

    &:hover {
      --background: #00c96e;
    }
  }
}
