.file-upload {
  text-align: center;

  .upload-button {
    margin: 10px auto;
  }

  .my-drop-zone {
    margin: 10px auto;
    max-width: 140px;
    min-height: 100px;
    border: 3px dashed #ddd;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: 16px;
      color: #ccc;
    }

    .nv-file-over-font {
      color: #006ac1;
    }
  }

  small {
    font-size: 86%;
    margin-left: 8px;

    a.remove-image {
      cursor: pointer;
      color: #f33510; // color($colors, danger);
    }
  }

  .progress-container {
    position: relative;
    margin: 0 auto;
    width: 80%;

    .progress-bar {
      height: 5px;
      background-color: #aaa;
      box-sizing: content-box;
      // width: 100%;
    }

    .progress-number {
      background-color: white;
      color: #929292;
      position: absolute;
      // left: 16px;
      font-weight: bold;
      top: 5px;
      font-size: 14px;
      padding: 5px;
    }
  }

  .upload-preview {
    height: 100px;
    width: auto;
    margin: 10px auto;
  }

  .nv-file-over {
    border: 3px dashed #426ab2; // #007ee5;
  }

  // &.item-md {
  //   padding-left: 0;
  // }
  button.item-button {
    height: 40px;
  }

  .divider {
    border-bottom: 1px solid #ddd;
    width: 80%;
    margin: 15px auto;

    p {
      color: #bbb;
      position: absolute;
      left: 50%;
      margin-left: -14px;
      margin-top: -13px;
      background-color: white;
      padding: 6px;
      font-style: italic;
    }
  }
}

.fileUpload {
  // position: relative;
  // overflow: hidden;
  // margin: 10px;
  // height: 40px;
  overflow: hidden;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  ion-icon {
    font-size: 32px;
  }

  &.item-button {
    padding: 18px 12px;
    font-size: 1.2rem;
  }

  input.upload {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}

.profile-upload {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #f33510;
  background-color: white;
  overflow: hidden;
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    color: #d88c00 !important;
    background-color: var(--ion-input-background) !important;
  }

  ion-icon {
    font-size: 20px;
  }

  input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}

.post-images-upload {
  cursor: pointer;

  ion-thumbnail {
    --size: 42px;
  }

  .fab-button-small {
    width: 30px;
    height: 30px;
    margin: 0;
  }

  .upload-preview-container {
    width: 100%;
    height: 120px;
    border: 1px solid #dddddd;
    margin-bottom: 8px;
    
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  input.post-images-upload-input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    left: 0;
    bottom: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
