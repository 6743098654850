.profile-messages-component {
  --background: transparent;
  background-color: var(--ion-page-background);

  ion-grid {
    max-width: 1000px;

    ion-item {
      &.conversation {
        cursor: pointer;

        &:hover {
          background-color: #f1f1f1;
        }
      }

      .message-bubble {
        max-width: 90%;
        border-radius: 8px;
        background-color: var(--ion-input-background);
        padding: 2px 16px 6px 16px;
        margin-bottom: 4px;
        clear: both;
        float: left;
        position: relative;

        &:not(.owner) {
          border-top-left-radius: 0;
        }
        
        .message-body {
          white-space: pre-line;
        }

        .action-bubble {
          position: absolute;
          top: 0;
          right: -26px;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: #f1f1f1;
          color: black;
          opacity: 0;
          font-size: 18px;
          text-align: center;
          line-height: 32px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: #d1d1d1;
          }
        }

        &.owner {
          float: right;
          background-color: var(--ion-input-background-chat-1);
          text-align: right;
          border-top-right-radius: 0;

          .action-bubble {
            right: auto;
            left: -26px;
          }
        }

        &:hover {
          .action-bubble {
            opacity: 1;
          }
        }
      }
    }
  }
}
