.search-short {
  border-radius: 4px;
  height: 42px;
  margin: 2px 0;
}

.search-control {
  --border-color: none !important;
  // background-color: rgba(255, 255, 255, 0.9);
  --background: var(--ion-input-background) !important;
  border-radius: 5px;
  min-height: 44px !important;
}

.search-results {
  position: absolute;
  width: 90%;
  z-index: 1000;
  padding: 0;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  max-height: 420px;
  overflow-y: auto;

  ion-item.dropdown-item {
    border-radius: 0 !important;

    &:hover {
      --background: rgb(32, 99, 224);
      color: white;
      cursor: pointer;
    }
  }
}

app-search-form {
  .grid {
    padding: 0;
  }

  .autocomplete-field {
    padding: 10px;
    border-radius: 5px;
    border-color: transparent;
    width: 100%;
    color: var(--ion-text-color);

    &::placeholder {
      color: var(--ion-text-color-secondary);
    }

    &:focus {
      outline: none;

      input {
        outline: none;
        caret-color: var(--ion-text-color);
      }
    }
  }

  .completer-description {
    float: right;
    margin-right: 8px;
    color: #222;
  }

  .item {
    border-radius: 4px;
    padding-left: 0;
    --background: var(--ion-input-background);
    --inner-border-width: 0 0 0 0;

    .item-inner {
      padding-left: 6px;
    }
  }

  .select-wrapper {
    justify-content: left;
    padding-inline-start: 0 !important;
    --padding-start: 0px !important;
  }

  .property-type-select {
    color: var(--ion-text-color);
    --border-width: 0 0 0 0;
    --min-height: 44px;

    .label-text-wrapper {
      display: none;
    }

    &:hover {
      ion-label,
      ion-select {
        color: var(--ion-text-color);
      }
    }

    ion-label {
      width: auto;
    }

    ion-select {
      width: 100%;
    }
    // ion-select::part(placeholder),
    // ion-select::part(text) {
    //   flex: 1 1 100%;
    // }
  }

  .form-input {
    border: 1px solid #999999;
  }

  .property-type-select {
    .label {
      margin: 10px;
      font-size: 1.4rem;
      color: #222;
    }

    ion-select {
      min-height: 44px; // ionic override
    }

    // .item-inner {
    //   padding-left: 6px;
    // }
  }

  .property-type {
    select {
      width: 100%;
      border-radius: 4px;
      // padding: 10px;
      border: 1px solid white;
    }

    .short-radio {
      padding: 0;

      .item {
        background-color: transparent;
        font-size: 14px;
        --background: transparent;
        --inner-border-width: 0 0 0 0;

        .item-inner {
          border-bottom: 1px solid transparent;
          padding-left: 0;

          .label {
            color: white;
            text-shadow: 0 0 10px #a34f00;
          }
        }
      }

      .radio {
        margin: 10px 14px 8px 4px;
      }
    }

    .property-type-item {
      width: 50%;
      float: left;

      .radio {
        margin: 10px 14px 8px 4px;
      }
    }
  }

  .long-form {
    color: #727272;
    font-size: 15px;
    padding: 0 16px;
    margin-bottom: -16px;

    h4 {
      color: #f37700;
      font-size: 16px;
      margin-bottom: 0;
    }

    .completer-input {
      border: 1px solid var(--ion-border-color);
    }

    .submit-button {
      position: sticky;
      bottom: 0px;
      left: 0px;
      // background: white;
      background: var(--ion-background-color);
      z-index: 9;
    }

    ion-row {
      width: 100%;
      margin-bottom: 16px;

      .item {
        background-color: transparent;
        --background: transparent;
        --inner-border-width: 0 0 0 0;

        .item-inner {
          border-bottom: none;
        }
      }

      .input-item {
        .item-inner {
          border: 1px solid #ddd;
          border-radius: 4px;
        }
      }
    }
  }
}
