.left-menu {
  --width: 100%;
  --max-width: 242px;
}

.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--ion-card-background);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}