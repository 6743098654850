.home-page {
  background-color: #f8f8f8;

  h1 {
    font-size: 26px;
    text-align: center;
    margin-bottom: 18px;

    @media only screen and (min-width: 575px) {
      font-size: 32px;
      margin-bottom: 32px;
    }
  }

  .no-items-message {
    font-size: 16px;
    text-align: center;
    padding: 40px 0 0 0;
    width: 100%;
  }

  .latest-properties {
    padding: 40px 0;
    background-color: var(--ion-page-background);

    .show-all {
      font-size: 16px;
      text-align: center;
    }
  }

  .last-viewed {
    padding: 40px 0;
    background-color: var(--ion-history-background);
  }

  ion-grid {
    padding: 0;
    // height: 100%;
    // height: calc(100vh - 56px);

    .jumbo {
      background: var(--ion-jumbo-background); // linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.5)), url("../../../assets/images/condo-323780.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      padding: 60px 0;
      display: flex;
      align-items: center;

      @media only screen and (min-width: 575px) {
        min-height: 50vh;
      }

      @media only screen and (min-width: 900px) {
        min-height: 70vh;
      }

      .search-box {
        width: 96%;
        max-width: 680px;
        margin: 0 auto;
        min-height: 160px;
        border-radius: 8px;
        padding: 16px 16px;
        background-color: #f37700;
        background-color: rgba(243, 119, 0, 0.9);
      }

      // h1 {
      //   font-size: 3rem;
      //   color: white;
      //   margin-bottom: 16px;
      //   margin-top: 6px;
      //   text-align: center;
      //   text-shadow: 0 0 14px #222;
      // }

      h1 {
        font-weight: 500;
        color: #007af5;
        margin-bottom: 16px;
        margin-top: 6px;
        // text-shadow: 0 0 12px #ffffff, 0 0 6px #ffffff;

        @media only screen and (min-width: 900px) {
          font-size: 3rem;
          margin-bottom: 3rem;
        }
      }

      .advanced-search {
        text-align: center;
        margin-top: 16px;
        margin-bottom: -10px;

        a {
          cursor: pointer;
          color: white;
          font-size: 15px;
          font-weight: 500;

          &:hover {
            opacity: 1;
            text-decoration: underline;
          }
        }
      }
    }

    // .ad-list {
    //   overflow-y: scroll;
    // }

    // .map-col {
    //   padding: 0;
    // }
  }

  .create-section {
    background-color: var(--ion-create-background);
    padding: 50px 20px;
    // text-align: center;

    h1 {
      color: #00ff8d;
    }

    p {
      font-size: 18px;
    }

    img {
      width: 100%;
      max-width: 360px;
      margin: 0 auto;
    }
  }

  .links-section {
    background-color: var(--ion-links-background);
    padding: 50px 20px;
  }

  .page-footer {
    padding: 50px 20px;
    background-color: ddd;
    background: var(--ion-footer-background);

    p {
      font-size: 15px;
      color: grey;
    }
  }
}
