.photos-component {
  ion-grid {
    max-width: 1000px;
    
    .saved-images {
      padding-bottom: 40px;
      padding-left: 0;
      
      .image-container {
        width: 100%;
        height: 120px;
        border: 1px solid #dddddd;
        margin-bottom: 8px;
        
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }

      p {
        font-size: 85%;
      }
      
      a {
        margin-left: 16px;
        cursor: pointer;
        color: var(--ion-color-danger);
      }
      
      ion-thumbnail {
        text-align: center;
        
        img {
          margin: 0 auto 12px auto;
          width: 120px;
          height: 80px;
        }
        
        a {
          margin-left: 16px;
          font-size: 85%;
          cursor: pointer;
          color: var(--ion-color-danger);
        }
      }
    }
    
    .image-upload .item-inner {
      border-bottom: none;
    }
  }
}
