.form-content {
  --background: transparent;
  background-color: var(--ion-page-background);

  ion-datetime-button.calendar-button button,
  .calendar-button .ion-activatable,
  ion-datetime-button {
    // color: #f9f9f9;
    // background-color: var(--ion-color-primary-tint);
    color: var(--ion-text-color);
    background: var(--ion-input-background);
  }

  ion-grid {
    max-width: 1000px;

    ion-list {
      background: transparent;
    }

    form {
      width: 100%;

      .danger {
        color: #c40000;
      }

      .slope-direction {
        margin-bottom: 16px;
      }

      ion-item {
        --background: transparent;

        span.vselitev {
          margin-top: 8px;
          display: inline-block;
        }

        .calendar-modal-button {
          margin: -6px 0 0 20px;
        }
      }

      .form-extras {
        width: 100%;
        vertical-align: top;

        // .new-ad-map {
        //   width: 100% !important;
        //   height: 300px !important;
        // }
        #form-map {
          min-height: 300px;
        }
      }

      // .form-inputs {
      //   width: 100%;
      //   vertical-align: top;
      //   // text-align: center;
      //   // margin-top: 20px;

      // }

      ion-item-divider {
        background-color: transparent;
        border-bottom: 1px solid;
        font-size: 20px;
        color: #f37700; // #0041b9;
        margin-bottom: 8px;
        margin-top: 18px;
      }

      // ion-label[fixed] {
      //   flex: 0 0 100px;
      //   width: 150px;
      //   min-width: 150px;
      //   max-width: 150px;
      // }

      ion-label {
        min-width: 160px;
      }
      
      ion-input,
      ion-textarea {
        --background: var(--ion-input-background);
        --padding-start: 12px;
        --border-radius: 4px;
        margin-top: 8px;
        margin-bottom: 8px;

        label {
          margin-top: 4px;
        }
      }

      ion-input {
        min-height: 42px;
        max-width: 360px;
        margin-bottom: 8px;
      }

      .item-inner {
        border-bottom: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
      }

      .item-select {
        border: 1px solid #dddddd;
        border-radius: 5px;
        margin-top: 12px;
      }

      .item-input:last-child {
        border-bottom: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }

      .list-md
        .item-input.ng-valid.item-input-has-value:not(.input-has-focus):not(.item-input-has-focus):last-child,
      .list-md
        .item-input.ng-valid.input-has-value:not(.input-has-focus):not(.item-input-has-focus):last-child {
        border-bottom-color: transparent;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }

      .list-md .item-input.item-input-has-focus::last-child,
      .list-md .item-input.input-has-focus::last-child {
        border-bottom-color: transparent;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
  }
}
