.image-slider-component {
  position: relative;
  // background-image: url('../../../assets/images/image.png');
  // background-size: 80%;
  // background-repeat: no-repeat;
  // background-position: center;
  
  .slides-photo-preview {
    height: calc(100vh - 56px);
    
    .preview-slide-image {
      max-height: 100%;
      max-width: 100%;
      height: inherit !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  
  .slide-image {
    width: 100%;
    // height: 160px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .slide-number {
    position: absolute;
    bottom: 8px;
    left: 50%;
    margin-left: -10px;
    color: #555;
    background-color: white;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 20px;
    height: 16px;
    width: auto;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding: 0 6px;
    z-index: 1;
  }
  
  .slide {
    position: absolute;
    top: 0;
    height: 100%;
    width: 15%;
    z-index: 1;
    cursor: pointer;
    transition: 0.25s;
    
    &:hover {
      .arrow {
        opacity: 0.8;
      }
    }
    
    &.slide-left {
      left: 0;
      
      &:hover {
        background-image: linear-gradient(
        to right,
        rgba(30, 30, 30, 0.25),
        rgba(200, 200, 200, 0)
        );
      }
    }
    
    &.slide-right {
      right: 0;
      
      &:hover {
        background-image: linear-gradient(
        to left,
        rgba(30, 30, 30, 0.25),
        rgba(200, 200, 200, 0)
        );
      }
    }
    
    .arrow {
      position: absolute;
      top: 40%;
      cursor: pointer;
      opacity: 0.2;
      padding: 16px;
      box-shadow: 2px -2px 0 1px #fff inset;
      border: solid transparent;
      border-width: 0 0 16px 16px;
      -webkit-transition: 0.2s;
      transition: 0.2s;
      
      &:hover {
        opacity: 0.8;
      }
      
      &.arrow-right {
        -webkit-transform: rotate(225deg);
        transform: rotate(225deg);
        right: -4px;
      }
      
      &.arrow-left {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        left: -4px;
      }
    }
  }
}
