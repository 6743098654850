.language-flag {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.login-header {

  .flag-button {
    padding: 0 10px;

    &:hover {
      color: white;
      background-color: #5cc7b1;
      background-color: rgba(255,255,255,0.25);
    }
  }
}

.language-popover {
  margin-bottom: 4px;

  .flag-button {
    
    &:hover {
      background-color: #f8f8f8;
    }
    
    .language-flag {
      width: 22px;
      height: 22px;
      margin-left: 0;
      margin-right: 8px;
      margin-bottom: -4px;
    }
  }
  
}

.loginpage {

  &.max-width {
    max-width: 600px;
    margin: 0 auto;
  }

  .form {
    color: var(--ion-text-color);
    padding: 0 12px;
    // max-width: 400px;
    margin: 0 auto;
    position: relative;

    // @media screen and (min-width: 576px) {
    //   padding: 0 30px;
    // }

    .checkbox-item {
      margin-top: 16px;
      font-size: 90%;
      cursor: pointer;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 16px;
    }

    ion-list {
      background: transparent;

      ion-item {
        margin-bottom: 8px;
        --background: transparent;
        --inner-padding-end: 0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        ion-input {
          --background: var(--ion-input-background);
          border-radius: 4px;
          --padding-start: 8px;
          min-height: 42px;
          line-height: 32px;
          border-bottom: 1px solid #ddd;

          &.has-focus:not(.ion-invalid) {
            border-color: var(--highlight-color-focused);
          }

          &.ion-touched.ion-invalid {
            border-color: var(--highlight-color);
          }
        }
      }
    }

    .fb-button {
      --background: #3f65b3;
    }

    .register-link {
      position: absolute;
      top: 10px;
      right: 12px;
      cursor: pointer;
    }

    .forgot-password {
      cursor: pointer;
    }

    // ion-item.error-message.item.item-block.item-md {
    //   font-size: small;
    //   color: #18af90;
    //   min-height: 18px;
    //   text-align: right;

    //   .item-inner {
    //     border-bottom: 1px solid transparent;
    //     height: 18px;
    //     padding-right: 0;
        
    //     ion-label.label {
    //       margin: 0 !important;
    //     }     
    //   }
    // }

    .error-message {
      font-size: 80%;
      display: inline-block;
      margin-top: -4px;
      margin-bottom: 12px;
    }
    
    .item-md.item-input.input-has-focus .item-inner {
      border-bottom-color: #426ab2;
      box-shadow: inset 0 -1px 0 0 #426ab2;
    }

    .input-has-focus .label-md[floating] {
      color: #426ab2;
    }

    .list-md .item-input.input-has-focus:last-child {
      border-bottom-color: #426ab2;
      box-shadow: inset 0 -1px 0 0 #426ab2;
    }
  }

  .logo {

    img {
      display: block;
      margin: 0 auto;
    }

    h2 {
      text-align: center;
      font-style: italic;
      margin-top: 0px;
      // color: orange; // color($colors, secondary);
    }
  }

  ion-icon.visibility.icon.item-icon {
    color: #666;
    font-size: 24px;
    margin: 0;
    margin-bottom: -40px;
  }
}