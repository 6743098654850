ion-header.preview-modal-header {
  .toolbar-title-ios {
    text-align: left;
  }

  .new-tab-link {
    color: #fff;
    margin-left: -16px;
    margin-right: -8px;
    padding: 12px 16px;
  }

  ion-button {
    .saved {
      color: #f33510;
    }
  }

  .icon {
    // margin: 0 4px;

    &.close-icon {
      font-size: 22px;
    }
  }
}
