.detail-page-content {
  .photo-section {
    height: 400px;
    background-color: #222;
    cursor: pointer;

    @media only screen and (min-width: 576px) {
      height: 600px;
    }

    ion-grid {
      height: 100%;
      padding: 0;

      ion-row {
        height: 100%;
      }

      .image-col {
        background-size: cover;
        background-position: center;
        padding: 0;

        .extra-photo {
          height: 100%;
          width: 50%;
          float: left;
          background-size: cover;
          background-position: center;

          @media only screen and (min-width: 576px) {
            height: 50%;
            width: 100%;
          }
        }
      }
    }
  }

  ion-avatar {
    --border-radius: 50%;
    overflow: hidden;
  }

  .container {
    max-width: 1000px;
    margin: 0 auto;
    color: var(--ion-text-color-secondary);

    h1 {
      color: #f33510; // #f33510;
      border-bottom: 1px solid #f33510;
      padding-bottom: 8px;
      margin-top: 32px;
      margin-bottom: 24px;
      font-size: 18px;
    }

    h2 {
      font-size: 18px;
      font-weight: 600;
    }

    h3 {
      // color: color($colors, complementary);// #f33510;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
    }

    @media only screen and (min-width: 575px) {
      h1 {
        font-size: 24px;
      }

      h2 {
        font-size: 20px;
      }
    }

    .message-textarea {
      margin: 16px 0;
      
      textarea {
        background-color: var(--ion-input-background);
        border: 1px solid var(--ion-input-background);
        border-radius: 4px;
        padding: 8px;
      }
    }

    .overview-data {
      padding: 0 16px;

      .price {
        .per-month {
          font-size: 18px;
        }
      }

      .amenities {
        margin: 0;

        .icon {
          margin-right: 12px;
          color: #f33510; // color($colors, secondary);
        }

        &.not-present {
          opacity: 0.5;
        }
      }

      .size {
        font-weight: bold;
        font-size: 18px;
      }

      .price-per-m {
        font-weight: 400;
        font-size: 70%;
      }

      .meta-overview {
        .meta-value {
          font-weight: bold;
        }
      }

      .detail {
        margin: 0;
      }

      .description {
        white-space: pre-line;
      }

      .cost {
        margin: 0;

        .energy-class {
          width: 80px;
          text-align: center;
          padding: 2px 0;
          display: inline-block;
          border-radius: 4px;
          text-transform: uppercase;

          &.a1 {
            background-color: #4fd14f;
            color: #fff;
          }

          &.a2 {
            background-color: #b9d14f;
            color: #fff;
          }

          &.a3 {
            background-color: #cac142;
            color: #fff;
          }
        }
      }

      .label {
        display: inline-block;
        // font-weight: 600;
        min-width: 180px;
      }
    }
  }
}
